<template>
    <v-container style="margin-top: 50px">
      <v-row>
        <v-col cols="6" offset="3">
          <v-btn :to="{ name: 'Manage Game', params: { id: `${$route.params.id}` } }" color="primary" outlined style="margin-bottom: 20px;">
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            Back to manage
          </v-btn>
          <v-snackbar v-model="showAddedSnackbar" :timeout="2000" top color="accent">Added team!</v-snackbar>
          <v-snackbar v-model="showSubmittedSnackbar" :timeout="2000" top color="accent">Submitted Answers!</v-snackbar>
          <v-select v-model="category" :items="categories" label="Current round" />
          <v-alert dense text outlined type="error" color="accent" style="margin-bottom: 20px" v-if="error">{{ errorMessage }}</v-alert>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-combobox v-model="name" :items="teams" label="Team Name" required v-on:input="joinGame" />
            <v-text-field v-for="i in 8" :key="i" :label="'Answer ' + i " v-model="answers[i - 1]"></v-text-field>
            <v-btn style="margin-top: 20px;" :loading="loading" @click="submitAnswers" color="accent">Submit</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import {mdiArrowLeft} from "@mdi/js";

export default {
  name: "HostEntry",
  metaInfo: {
    title: 'Host Entry'
  },
  mounted() {
    this.code = this.$route.params.id;
    this.$get(`${this.$apiEndpoint()}/manage/${this.code}`, {}).then((response) => {
      if (response instanceof Error) {
        return;
      }

      this.categories = response.data.categories.map((c) => ({text: c.categoryTitle, value: c.categoryId }));
    });
    this.$get(`${this.$apiEndpoint()}/manage/${this.code}/teams`, {}).then((response) => {
      if (response instanceof Error) {
        return;
      }

      this.teams = response.data.map((t) => t.name);
    });
  },
  data: () => ({
    icons: {
      mdiArrowLeft
    },
    code: '',
    showAddedSnackbar: false,
    showSubmittedSnackbar: false,
    category: '',
    categories: [],
    teams: [],
    loading: false,
    valid: true,
    answers: new Array(8).fill(''),
    error: false,
    errorMessage: '',
    name: ''
  }),
  methods: {
    joinGame(entry) {
      if (this.teams.indexOf(entry) === -1) {
        this.$post(`${this.$apiEndpoint()}/join`, {
          name: entry,
          game: this.code
        }, {}).then(response => {
          if (response instanceof Error) {
            this.errorMessage = (response.response.data) ? response.response.data : "Uh oh! Something went wrong. Reload the page and try again."
            this.error = true;
            return;
          }

          this.teams.push(entry);
          this.showAddedSnackbar = true;
        });
      }
    },
    submitAnswers() {
      let postData = {
        "team": this.name,
        "game": this.code,
        "answers": this.answers,
        "category": this.category
      }
      console.log(postData);
      this.$post(`${this.$apiEndpoint()}/submit`, postData).then(response => {
        if (response instanceof Error) {
          // Do something
          return;
        }
        if (response.status === 201) {
          this.showSubmittedSnackbar = true;
          this.answers = new Array(8).fill('');
          this.name = '';
        }
      });
    }
  }
}
</script>

<style scoped>

</style>